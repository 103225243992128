var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-2"
  }, [_c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("LPJ BOP")]), _vm.bop.lpj == 1 ? _c('div', [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("LPJ Sudah Disetor")])], 1) : _vm._e()]), _c('header', {
    staticClass: "mb-2 d-flex justify-content-between align-items-center"
  }, [_vm.isSales && _vm.bop.lpj == 0 ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addSellingNote($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Add Rincian LPJ ")], 1) : _vm._e(), _vm.isFinance && _vm.bop.appr_terima > 0 ? _c('b-button', {
    attrs: {
      "disabled": _vm.sellingNotes.length < 1,
      "variant": _vm.sellingNotes.length > 0 ? 'outline-danger' : 'outline-secondary'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.printLPJ($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak LPJ ")], 1) : _vm._e()], 1), !_vm.addAction ? _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "show": "",
      "fade": "",
      "variant": "primary"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" LPJ BOP Kosong ")])]) : _vm._e(), _vm.addAction ? _c('section', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.sellingNotes,
      "hover": "",
      "responsive": "",
      "bordered": "",
      "striped": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(add)",
      fn: function () {
        return [_vm.bop.lpj == 0 ? _c('b-button', {
          staticClass: "rounded-full",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.addList($event);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusCircleIcon"
          }
        })], 1) : _c('b-button', {
          attrs: {
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusCircleIcon"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "cell(no_nota)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm.isSales ? _c('b-form-input', {
          model: {
            value: item.no_nota,
            callback: function ($$v) {
              _vm.$set(item, "no_nota", $$v);
            },
            expression: "item.no_nota"
          }
        }) : _c('span', [_vm._v(" " + _vm._s(item.no_nota) + " ")])];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm.isSales ? _c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        }) : _c('p', [_vm._v(" " + _vm._s(item.keterangan) + " ")])];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm.isSales ? _c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }) : _c('p', [_vm._v(" " + _vm._s(item.qty) + " ")])];
      }
    }, {
      key: "cell(harga)",
      fn: function (_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [_vm.isSales ? _c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiah(index);
            }
          },
          model: {
            value: item.harga,
            callback: function ($$v) {
              _vm.$set(item, "harga", $$v);
            },
            expression: "item.harga"
          }
        }) : _c('p', [_vm._v(" " + _vm._s(item.harga) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref6) {
        var index = _ref6.index,
          item = _ref6.item;
        return [index > 0 && _vm.bop.lpj == 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "varian": "danger"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.remove(index, item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1) : _c('b-button', {
          attrs: {
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)];
      }
    }], null, false, 2410456176)
  })], 1), _c('b-row', {
    staticClass: "justify-content-end"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.bop.lpj == 0 && _vm.isSales ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveNota($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "SaveIcon"
    }
  }), _c('span', [_vm._v("Simpan")])], 1) : _c('b-button', {
    attrs: {
      "variant": "info",
      "disabled": ""
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "SaveIcon"
    }
  }), _c('span', [_vm._v("Simpan")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-end"
  }, [_c('table', {
    staticClass: "table table-bordered table-striped mb-2"
  }, [_c('tr', [_c('td', [_vm._v("Total Pengeluaran")]), _c('th', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.total)) + " ")])]), _c('tr', [_c('td', [_vm._v("Nominal")]), _c('th', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.bop.nominal)) + " ")])]), _vm.isReimburse ? _c('tr', [_c('td', [_vm._v("Reimburse")]), _c('th', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.sisa)) + " ")])]) : _vm._e(), _vm.isPengembalian ? _c('tr', [_c('td', [_vm._v("Pengembalian")]), _c('th', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.sisa)) + " ")])]) : _vm._e()])]), _vm.isSales ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.bop.lpj == 0 ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.setorNota($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "CornerUpRightIcon"
    }
  }), _c('span', [_vm._v("Setor BOP")])], 1) : _c('b-button', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("Sudah Disetor")])], 1) : _vm._e()])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }